import React from 'react'
import Img, { GatsbyImageProps } from 'gatsby-image'
import styled, { css } from 'styled-components'
import Form from './Form'
import { Container as FormWrapper } from './Contact'

const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  height: ${props => props.height || 'auto'};
  background: rgba(0, 0, 0, 0.3);
  @media (max-width: 500px) {
    height: 450px;
  }

  ${props =>
    props.pngImage &&
    css`
      @media (max-width: 500px) {
        height: ${props => props.height.replace('px', '') * 0.7 + 'px'};
      }
      background-color: #151d31;
      .gatsby-image-outer-wrapper {
        position: absolute !important;
        bottom: 0;
      }
    `}
`

interface IBgImageProps {
  height: string
  home: boolean
}

const Content = styled<IBgImageProps, any>('section')`
  position: absolute;
  margin: 0 10%;
  z-index: 99;
  height: ${props => props.height || 'auto'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  ${props =>
    props.home &&
    css`
      @media (max-width: 500px) {
        height: 500px;
      }

      right: 5vw;
      max-width: 500px;
      span {
        color: #ff002d;
        font-weight: 600;
        margin-bottom: 0;
        font-size: 22px;
      }
      h1 {
        font-size: 50px !important;
        i {
          color: #ff002d;
          font-style: normal;
        }
      }
      button {
        border: 0;
        border-bottom: 3px solid #ff002d;
        border-radius: 0;
        text-align: left;
        margin-top: 2rem;
        padding: 0 3rem 1rem 0 !important;
        &:hover {
          background-color: transparent;
          color: #ff002d;
        }
      }
    `}

  ${props =>
    !props.home &&
    css`
      @media (max-width: 900px) {
        text-align: center;
        margin: 0;
        width: 100%;
        height: auto;
        margin-top: 80px;

        p,
        button {
          display: none;
        }
      }

      @media (max-width: 500px) {
        align-items: center;
        p,
        button {
          display: block;
        }
      }
    `}

 
  h1,
  h2,
  h3 {
    font-size: 30px;
    margin: 1rem 0;
    color: #fff;
    text-shadow: 0 1px 55px rgba(0, 0, 0, 0.7);
  }
  p {
    color: #fff;
    font-size: 18px;
    max-width: 420px;
    width: auto;
    text-shadow: 0 1px 35px rgba(0, 0, 0, 0.7);
  }
`
const StaticImg = styled<IBgImageProps, any>(Img)`
  width: 500px;
  bottom: 0;
  left: 8vw;

  @media (max-width: 680px) {
    width: 90%;
    left: 0;
  }
`

const BgImage = styled<IBgImageProps, any>(Img)`
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  z-index: -1;
  height: ${props => props.height || 'auto'};

  @media (max-width: 500px) {
    height: 450px;
  }
`

const Figure = styled.figure`
  width: 100vw;
  height: ${props => props.height};
  background: url(${props => props.bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;

  @media (max-width: 500px) {
    background-size: cover;
    height: ${props => props.height.replace('px', '') * 0.7 + 'px'};
  }
`

const FormContainer = styled.div`
  width: 320px;
  background: #fff;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  position: absolute;
  right: 10%;
  top: calc(50% + 30px);
  transform: translateY(-50%);

  @media (max-width: 900px) {
    left: 50%;
    right: auto;
    bottom: 20px;
    top: auto;
    transform: translateX(-50%);
    width: 90%;
    max-width: 340px;
  }

  @media (max-width: 500px) {
    display: none;
  }

  input {
    padding: 0.75rem 1rem;
  }

  h2 {
    color: #333 !important;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    text-align: center;
    text-shadow: none;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`

interface IHeroProps {
  height: string
  bgImage: GatsbyImageProps
  children: React.ReactChild
  noForm: boolean
  pngImage: boolean
  home: boolean
}

const Hero = ({
  height,
  bgImage,
  children,
  noForm,
  pngImage,
  home
}: IHeroProps) => (
  <Container home={home} height={height} pngImage={pngImage}>
    <Content home={home} height={height}>
      {children}
    </Content>
    {pngImage && bgImage && <Figure height={height} bg={bgImage.fluid.src} />}
    {!pngImage && bgImage && <BgImage height={height} fluid={bgImage.fluid} />}
    {!noForm && (
      <FormContainer>
        <Form
          title="Contact us today!"
          showSuburb={false}
          showMessage={false}
        />
      </FormContainer>
    )}
  </Container>
)

export default Hero
