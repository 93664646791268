import * as React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Hero from '../components/Hero'
import Testimonials from '../components/Testimonials'
import Button from '../components/Button'

export const netballTestimonials = [
  {
    content: `In a 12 month period of time they helped us get our financial lives in order, purchase our first home, go on a 4 week Europe holiday, purchase a business and get engaged. Through all of the above the team at Parinity have made the whole process seamless, effective and hassle free`,
    name: 'KRIS & STEPH',
  },
  {
    content: `Their depth of knowledge and dedication have provided us with sound and rewarding advice that has been paramount in our recent property purchase. Their interest and personalised approach have also provided a sense of comfort and confidence in dealing with our financial affairs.`,
    name: 'DUGALD & BRODIE',
  },
  {
    content: `Being a part owner of two Dental surgeries as well as having a young family at home, it is very difficult to find the time to manage my total financial situation on a micro level.  Parinity have been able to assist with this and it has been great having a firm I can trust will get the work done, as well as leverage off their expertise.`,
    name: 'MIKE',
  },
  {
    content: `Parinity and I have worked together to discover my overall financial situation and goals, something I found difficult to do on my own due to a FIFO career. The team provide me with precise information which has allowed me to develop a share and property portfolio as well as gain insight into my tax, insurance and day to day money items.  Parinity have made the process very easy and stress free, they provide great service. `,
    name: 'JONO',
  },
  {
    content: `We have accomplished more in the past 4 years, with the Parinity team behind us than I have done in the past 30 years of working and looking after our own finances. We look forward to having Parinity working with us into the future.`,
    name: 'ANTHONY & KATRINA',
  },
  {
    name: 'IAN & DEB',
    content:
      'It really helps to see how the decisions we have made are helping to achieve the goals we discussed with you when we first sat down with Parinity a few years ago. It wasn’t easy for us to sell the house, and we are still “home” sick for a place of our own. But now we have a clear line of sight to our financial situation and the impact of our decisions.  Thanks Team Parinity!',
  },
  {
    name: 'HELEN',
    content:
      'What I have really been impressed with is Lance’s commitment to really understand what you want in life then how to go about it. The initial goal mapping exercise is such a great tool and not everyone does it! I really value the attention to detail and highly personalised level of service!',
  },
  {
    name: 'JASON & JAYN',
    content:
      'Engaged with Parinity and Lance has seen my financial position totally revamped end to end, from insurance to tax, property and Superannuation. Having a plan, having the advice and direction has made such a difference to my life and my family. Looking forward to a long and productive connection with Lance.',
  },
]

export const allTestimonials = [...netballTestimonials]

class Reviews extends React.Component {
  // componentDidMount() {
  //   const method = window.addEventListener ? 'addEventListener' : 'attachEvent'
  //   const event = window.addEventListener ? 'message' : 'onmessage'
  //   window[method](
  //     event,
  //     e => {
  //       if ('review_iframe_height' in e.data) {
  //         document.getElementById('myFrame').height =
  //           e.data.review_iframe_height + 'px'
  //       }
  //     },
  //     false
  //   )
  // }
  render() {
    return (
      <Layout header="dark">
        <Helmet>
          <title>
            Testimonials & Customer Reviews - Parinity Financial Professionals
          </title>
          <meta
            name="description"
            content="Read some of our latest client testimonials and reviews. Hear the stories and experiences from those who know best."
          />
        </Helmet>
        <Header style={{ paddingTop: '8rem' }}></Header>

        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          style={{ margin: '50px 0 100px' }}
        >
          <Testimonials
            noButton={true}
            testimonials={allTestimonials}
            style={{
              borderRadius: 8,
              boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
            }}
          />
        </Flex>

        <Hero
          bgImage={this.props.data.blurredHero.childImageSharp}
          height="550px"
        >
          <h3>Want to find out more?</h3>
          <p>
            Call 07 3114 2500 or complete the enquiry form to contact us today
            and find out how we can help.
          </p>
          <Flex>
            <Button
              onClick={(e) => {
                e.preventDefault()
                Calendly.showPopupWidget('https://calendly.com/lancecheung/')
              }}
              inverted={true}
            >
              Book an appointment now!
            </Button>
          </Flex>
        </Hero>
      </Layout>
    )
  }
}

export default Reviews

export const query = graphql`
  query TestimonialsQuery {
    blurredHero: file(relativePath: { eq: "cta-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
